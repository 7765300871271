import { gql } from "@apollo/client";
import { authResponseFragment } from "@api/graphql/queries/Fragments";

export const authMutation = gql`
    mutation auth($username: String!, $password: String!) {
        auth(username: $username, password: $password) {
            ...AuthResponse
        }
    }
    ${authResponseFragment}
`;

export const logoutMutation = gql`
    mutation logout {
        logout
    }
`;

export const refreshTokenMutation = gql`
    mutation refreshToken($refreshToken: String!) {
        refreshToken(token: $refreshToken)
    }
`;

export const createAccountMutation = gql`
    mutation createAccount($input: AccountCreateInput!) {
        createAccount(input: $input) {
            id
        }
    }
`;

export const updateAccountMutation = gql`
    mutation updateAccount($id: ID!, $input: AccountUpdateInput!) {
        updateAccount(id: $id, input: $input) {
            id
        }
    }
`;
