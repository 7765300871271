const localeHU = {
    common: {
        save: "Mentés",
        cancel: "Mégsem",
        search: "Keresés",
    },
    pages: {
        auth: {
            login: {
                title: "Bejelentkezés",
                form: {
                    username: {
                        label: "Felhasználónév",
                    },
                    password: {
                        label: "Jelszó",
                    },
                },
            },
        },
        dashboard: {
            title: "Áttekintés",
        },
        accounts: {
            list: {
                title: "Fiókok",
                buttons: {
                    create: "Létrehozás",
                },
                table: {
                    columns: {
                        id: "Azonosító",
                        username: "Felhasználónév",
                        email: "E-mail",
                        name: "Név",
                        createdAt: "Létrehozva",
                        updatedAt: "Módosítva",
                    },
                },
            },
            create: {
                title: "Fiók létrehozása",
                form: {
                    subtitle: "Fiók adatai",
                    description: "A fiók adatainak megadása",
                    username: {
                        label: "Felhasználónév",
                    },
                    email: {
                        label: "E-mail cím",
                    },
                    name: {
                        label: "Név",
                    },
                    password: {
                        label: "Jelszó",
                    },
                    passwordConfirmation: {
                        label: "Jelszó megerősítése",
                    },
                    isActive: {
                        label: "Aktív",
                    },
                },
            },
            edit: {
                title: {
                    loading: "Fiók betöltése",
                    loaded: "{username} - Fiók szerkesztése",
                },
                form: {
                    subtitle: "Fiók adatai",
                    description: "A fiók adatainak megadása",
                    username: {
                        label: "Felhasználónév",
                    },
                    email: {
                        label: "E-mail cím",
                    },
                    name: {
                        label: "Név",
                    },
                    password: {
                        label: "Jelszó",
                    },
                    passwordConfirmation: {
                        label: "Jelszó megerősítése",
                    },
                    isActive: {
                        label: "Aktív",
                    },
                },
            },
        },
        products: {
            list: {
                title: "Termékek",
                table: {
                    columns: {
                        id: "Azonosító",
                        sku: "Gyártói cikkszám",
                        name: "Név",
                        brand: "Márka",
                        description: "Leírás",
                        createdAt: "Létrehozva",
                        updatedAt: "Frissítve",
                        supplier: "Beszállító",
                        price: "Nettó Ár",
                        stock: "Készlet",
                    },
                },
            },
        },
    },
    components: {
        dataGrid: {
            pagination: {
                total: "{currentRowsFrom}-{currentRowsTo} / {totalRows} összesen",
            },
        },
    },
    menu: {
        dashboard: "Áttekintés",
        accounts: "Fiókok",
        products: "Termékek",
    },
    enums: {
        validatorMessage: {
            greaterThanZero: "A mező értéke nagyobb kell legyen, mint 0.",
            invalidEmail: "Helytelen e-mail cím",
            invalidUsername: "Helytelen felhasználónév, legalább 3 karakter hosszúnak kell lennie.",
            required: "Kötelező mező",
            weakPassword: "A jelszó túl gyenge, legalább 8 karakter hosszúnak kell lennie.",
            passwordsDoNotMatch: "A jelszavak nem egyeznek",
        },
        supplier: {
            CPL: "ComParts Line",
            SENETIC: "Senetic",
            COMPMARKET: "CompMarket",
        },
        stock: {
            OUT_OF_STOCK: "Kifogyott",
            IN_STOCK: "Raktáron",
        },
    },
    errors: {
        api: {
            UNKNOWN: "Ismeretlen hiba",
            UNAUTHORIZED: "Kiléptetve",
            UNAUTHENTICATED: "Kiléptetve",
            INVALID_REQUEST: "Érvénytelen kérés",
            INVALID_RESPONSE: "Érvénytelen válasz",
            NETWORK_ERROR: "Hálózati hiba",
            REQUEST_TIMEOUT: "Kérés időtúllépés",
            NOT_ALLOWED: "Nem engedélyezett",
            FORBIDDEN: "Tiltott",
            BAD_REQUEST: "Hibás kérés",
            ACCOUNT_LOCKED: "Fiók zárolva",
            ACCOUNT_NOT_ACTIVATED: "Fiók nincs aktiválva",
            ACCOUNT_NOT_FOUND: "Fiók nem található",
            ILLEGAL_STATE: "Tiltott állapot",
            BAD_CREDENTIALS: "Hibás hitelesítő adatok",
            USERNAME_ALREADY_EXISTS: "Felhasználónév már létezik",
            EMAIL_ALREADY_EXISTS: "E-mail cím már létezik",
        },
    },
};

export default localeHU;
