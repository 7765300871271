import React, { useEffect, useState } from "react";
import { PageWrapper } from "@components/PageWrapper";
import { useIntl } from "react-intl";
import { useQuery } from "@apollo/client";
import { Gql } from "@api/Api";
import { accountQuery } from "@api/graphql/queries/Queries";
import { useParams } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Box, Button, Card, Divider, Grid2 as Grid, Switch, TextField, Typography } from "@mui/material";
import { ValidatorMessage, ValidatorService } from "@services/ValidatorService";
import * as _ from "lodash";

type FormValues = {
    name: string;
    email: string;
    password: string;
    passwordConfirmation: string;
    isActive: boolean;
};

const getDefaultValues = (account?: Gql.AccountFragment | null): FormValues => {
    return {
        name: account?.name ?? "",
        email: account?.email ?? "",
        password: "",
        passwordConfirmation: "",
        isActive: account?.isActive ?? false,
    };
};

export const AccountEditPage = () => {
    const params = useParams<{ id: string }>();
    const intl = useIntl();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [emailError, setEmailError] = useState<string | null>(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [error, setError] = useState<string | null>(null);

    const { data: accountData, loading } = useQuery<Gql.accountQuery, Gql.accountQueryVariables>(accountQuery, {
        variables: {
            id: params.id ?? "",
        },
    });

    const { control, handleSubmit, reset, watch } = useForm<FormValues>({
        defaultValues: getDefaultValues(accountData?.account),
        mode: "onSubmit",
    });

    const onSubmit: SubmitHandler<FormValues> = async (values: FormValues): Promise<void> => {
        return;
    };

    useEffect(() => {
        reset(getDefaultValues(accountData?.account));
    }, [accountData?.account, reset]);

    return (
        <PageWrapper
            title={intl.formatMessage({ id: loading ? "pages.accounts.edit.title.loading" : "pages.accounts.edit.title.loaded" }, { username: accountData?.account?.username })}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box p={5} pt={0}>
                    <Grid container spacing={5}>
                        <Grid size={8}>
                            <Card sx={{ padding: 3 }} variant={"outlined"}>
                                <Box>
                                    <Typography variant={"h5"}>{intl.formatMessage({ id: "pages.accounts.edit.form.subtitle" })}</Typography>
                                    <Typography variant={"body2"} fontSize={16} fontWeight={500} color={"textSecondary"} mt={1}>
                                        {intl.formatMessage({ id: "pages.accounts.edit.form.description" })}
                                    </Typography>
                                </Box>
                                <Divider sx={{ my: 3 }} />
                                <Grid container spacing={3}>
                                    <Grid size={6}>
                                        <TextField
                                            type={"text"}
                                            fullWidth
                                            label={intl.formatMessage({ id: "pages.accounts.edit.form.username.label" })}
                                            hiddenLabel={true}
                                            variant="outlined"
                                            value={accountData?.account?.username}
                                            disabled={true}
                                        />
                                    </Grid>
                                    <Grid size={6}>
                                        <Controller
                                            name="email"
                                            rules={{ validate: ValidatorService.email }}
                                            control={control}
                                            render={({ field: { onChange, onBlur, value, name }, fieldState }) => (
                                                <TextField
                                                    type={"text"}
                                                    helperText={
                                                        !_.isNil(fieldState.error)
                                                            ? intl.formatMessage({ id: `enums.validatorMessage.${fieldState.error.message}` })
                                                            : emailError
                                                              ? emailError
                                                              : ""
                                                    }
                                                    error={!!fieldState.error || !!emailError}
                                                    fullWidth
                                                    label={intl.formatMessage({ id: `pages.accounts.edit.form.${name}.label` })}
                                                    hiddenLabel={true}
                                                    variant="outlined"
                                                    value={value}
                                                    onBlur={onBlur}
                                                    onChange={onChange}
                                                    disabled={loading}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid size={12}>
                                        <Controller
                                            name="name"
                                            rules={{ validate: ValidatorService.required }}
                                            control={control}
                                            render={({ field: { onChange, onBlur, value, name }, fieldState }) => (
                                                <TextField
                                                    type={"text"}
                                                    helperText={!_.isNil(fieldState.error) ? intl.formatMessage({ id: `enums.validatorMessage.${fieldState.error.message}` }) : ""}
                                                    error={!!fieldState.error}
                                                    fullWidth
                                                    label={intl.formatMessage({ id: `pages.accounts.edit.form.${name}.label` })}
                                                    hiddenLabel={true}
                                                    variant="outlined"
                                                    value={value}
                                                    onBlur={onBlur}
                                                    onChange={onChange}
                                                    disabled={loading}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid size={6}>
                                        <Controller
                                            name="password"
                                            rules={{
                                                validate: {
                                                    required: ValidatorService.required,
                                                    password: ValidatorService.password,
                                                },
                                            }}
                                            control={control}
                                            render={({ field: { onChange, onBlur, value, name }, fieldState }) => (
                                                <TextField
                                                    type={"password"}
                                                    helperText={!_.isNil(fieldState.error) ? intl.formatMessage({ id: `enums.validatorMessage.${fieldState.error.message}` }) : ""}
                                                    error={!!fieldState.error}
                                                    fullWidth
                                                    label={intl.formatMessage({ id: `pages.accounts.edit.form.${name}.label` })}
                                                    hiddenLabel={true}
                                                    variant="outlined"
                                                    value={value}
                                                    onBlur={onBlur}
                                                    onChange={onChange}
                                                    disabled={loading}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid size={6}>
                                        <Controller
                                            name="passwordConfirmation"
                                            rules={{
                                                validate: {
                                                    required: ValidatorService.required,
                                                    match: (value: string) => {
                                                        return value === watch("password") || ValidatorMessage.passwordsDoNotMatch;
                                                    },
                                                },
                                            }}
                                            control={control}
                                            render={({ field: { onChange, onBlur, value, name }, fieldState }) => (
                                                <TextField
                                                    type={"password"}
                                                    helperText={!_.isNil(fieldState.error) ? intl.formatMessage({ id: `enums.validatorMessage.${fieldState.error.message}` }) : ""}
                                                    error={!!fieldState.error}
                                                    fullWidth
                                                    label={intl.formatMessage({ id: `pages.accounts.edit.form.${name}.label` })}
                                                    hiddenLabel={true}
                                                    variant="outlined"
                                                    value={value}
                                                    onBlur={onBlur}
                                                    onChange={onChange}
                                                    disabled={loading}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                {!_.isNil(error) && (
                                    <Typography variant={"subtitle1"} fontWeight={600} color={"error"} mt={1}>
                                        {error}
                                    </Typography>
                                )}
                            </Card>
                        </Grid>
                        <Grid size={4}>
                            <Card sx={{ padding: 3 }} variant={"outlined"}>
                                <Grid container spacing={3}>
                                    <Grid size={6}>
                                        <Button variant={"contained"} color={"inherit"} fullWidth={true} size={"large"} disabled={loading}>
                                            {intl.formatMessage({ id: "common.cancel" })}
                                        </Button>
                                    </Grid>
                                    <Grid size={6}>
                                        <Button variant={"contained"} color={"primary"} fullWidth={true} size={"large"} disabled={loading} type={"submit"}>
                                            {intl.formatMessage({ id: "common.save" })}
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Divider sx={{ my: 3 }} />
                                <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                    <Grid size={"auto"}>
                                        <Typography variant={"body2"} fontWeight={600}>
                                            {intl.formatMessage({ id: "pages.accounts.edit.form.isActive.label" })}
                                        </Typography>
                                    </Grid>
                                    <Grid size={"auto"}>
                                        <Controller
                                            name={"isActive"}
                                            control={control}
                                            render={({ field: { onChange, onBlur, value, name }, fieldState }) => (
                                                <Switch checked={value} defaultChecked={value} onChange={(_, checked) => onChange(checked)} disabled={loading} />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </PageWrapper>
    );
};
