export class Paths {
    public static readonly login: string = "/login";

    public static readonly root: string = "/";

    public static readonly dashboard: string = "/dashboard";

    public static readonly accounts: string = "/accounts";
    public static readonly accountCreate: string = `${Paths.accounts}/create`;
    public static readonly accountEdit = (id: string) => `${Paths.accounts}/${id}/edit`;

    public static readonly products: string = "/products";
}
