import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { To } from "@remix-run/router";
import { NavigateOptions } from "react-router/dist/lib/context";

type Props = {
    to: To;
    options?: NavigateOptions;
};

export const Redirect = ({ to, options }: Props) => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate(to, options);
    }, [navigate, to, options]);

    return <></>;
};
