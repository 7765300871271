import { Box, css, IconButton, TableCell, TableHead, TableRow, styled } from "@mui/material";
import { hexToRGBA } from "@theme/Theme";
import { grey } from "@mui/material/colors";

export type DataGridData<T> = T & {
    id: string | number;
};

export const LoadingContainer = styled(Box)`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: ${({ theme }) => hexToRGBA(theme.palette.primary.main, 0.08)};
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const PaginationButton = styled(IconButton)`
    color: ${({ theme }) => theme.palette.primary.main};
`;

export const DataGridTableRow = styled(TableRow)<{ $clickable?: boolean }>`
    &:hover {
        background-color: ${({ theme }) => hexToRGBA(theme.palette.primary.main, 0.08)};
    }

    ${props =>
        props.$clickable === true &&
        css`
            cursor: pointer;
        `}
`;

export const DataGridColumnSeparator = styled(Box)`
    min-height: 20px;
    width: 2px;
    background-color: ${grey[200]};
    z-index: 100;
    position: absolute;
    right: 0;
    top: 18px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
`;

export const DataGridHeadTableCell = styled(TableCell)`
    position: relative;
`;

export const DataGridTableHead = styled(TableHead)`
    &:hover {
        ${DataGridColumnSeparator} {
            opacity: 1;
        }
    }
`;
