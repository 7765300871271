import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { AuthReducer } from "@redux/slices/AuthSlice";
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const reducer = combineReducers({
    auth: AuthReducer,
});

const persistConfig = {
    key: "tadam-admin",
    keyPrefix: process.env.REACT_APP_ENVIRONMENT,
    storage,
    whitelist: ["auth"],
};

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => {
        return getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        });
    },
});

export type RootState = ReturnType<typeof store.getState>;

export const persistor = persistStore(store);
