import React, { useState } from "react";
import { PageWrapper } from "@components/PageWrapper";
import { useIntl } from "react-intl";
import { ActiveBreadcrumbItem, Breadcrumbs } from "@components/Breadcrumbs";
import { Paths } from "@models/Paths";
import { Link } from "@components/Link";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Box, Button, Card, Divider, Grid2 as Grid, Switch, TextField, Typography } from "@mui/material";
import { ValidatorMessage, ValidatorService } from "@services/ValidatorService";
import * as _ from "lodash";
import { useMutation } from "@apollo/client";
import { Gql } from "@api/Api";
import { createAccountMutation } from "@api/graphql/queries/Mutations";
import { GraphQLClient } from "@api/graphql/GraphQLClient";
import { ApiErrorCode } from "@api/ApiError";
import { useNavigate } from "react-router-dom";

type FormValues = {
    username: string;
    name: string;
    email: string;
    password: string;
    passwordConfirmation: string;
    isActive: boolean;
};

export const AccountCreatePage: React.FC = () => {
    const intl = useIntl();
    const navigate = useNavigate();

    const [emailError, setEmailError] = useState<string | null>(null);
    const [usernameError, setUsernameError] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    const [createAccountFn, { loading }] = useMutation<Gql.createAccountMutation, Gql.createAccountMutationVariables>(createAccountMutation, {
        onCompleted: () => {
            navigate(Paths.accounts);
        },
        onError: error => {
            const apiError = GraphQLClient.getError(error);

            if (_.isNil(apiError)) {
                setError(intl.formatMessage({ id: "errors.api.UNKNOWN" }));
                return;
            }

            switch (apiError.code) {
                case ApiErrorCode.EMAIL_ALREADY_EXISTS:
                    setEmailError(intl.formatMessage({ id: apiError.i18nKey }));
                    break;
                case ApiErrorCode.USERNAME_ALREADY_EXISTS:
                    setUsernameError(intl.formatMessage({ id: apiError.i18nKey }));
                    break;
                default:
                    setError(intl.formatMessage({ id: apiError.i18nKey }));
                    break;
            }
        },
    });

    const { control, handleSubmit, watch } = useForm<FormValues>({
        defaultValues: {
            username: "",
            name: "",
            email: "",
            password: "",
            passwordConfirmation: "",
            isActive: true,
        },
        mode: "onSubmit",
    });

    const onSubmit: SubmitHandler<FormValues> = async (values: FormValues): Promise<void> => {
        setError(null);
        setEmailError(null);
        setUsernameError(null);
        await createAccountFn({
            variables: {
                input: {
                    username: values.username,
                    name: values.name,
                    email: values.email,
                    password: values.password,
                    isActive: values.isActive,
                },
            },
        });
    };

    return (
        <PageWrapper
            title={intl.formatMessage({ id: "pages.accounts.create.title" })}
            breadcrumb={
                <Breadcrumbs>
                    <Link to={Paths.accounts} color={"inherit"}>
                        {intl.formatMessage({ id: "pages.accounts.list.title" })}
                    </Link>
                    <ActiveBreadcrumbItem>{intl.formatMessage({ id: "pages.accounts.create.title" })}</ActiveBreadcrumbItem>
                </Breadcrumbs>
            }
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box p={5} pt={0}>
                    <Grid container spacing={5}>
                        <Grid size={8}>
                            <Card sx={{ padding: 3 }} variant={"outlined"}>
                                <Box>
                                    <Typography variant={"h5"}>{intl.formatMessage({ id: "pages.accounts.create.form.subtitle" })}</Typography>
                                    <Typography variant={"body2"} fontSize={16} fontWeight={500} color={"textSecondary"} mt={1}>
                                        {intl.formatMessage({ id: "pages.accounts.create.form.description" })}
                                    </Typography>
                                </Box>
                                <Divider sx={{ my: 3 }} />
                                <Grid container spacing={3}>
                                    <Grid size={6}>
                                        <Controller
                                            name="username"
                                            rules={{
                                                validate: {
                                                    required: ValidatorService.required,
                                                    username: ValidatorService.username,
                                                },
                                            }}
                                            control={control}
                                            render={({ field: { onChange, onBlur, value, name }, fieldState }) => (
                                                <TextField
                                                    type={"text"}
                                                    helperText={
                                                        !_.isNil(fieldState.error)
                                                            ? intl.formatMessage({ id: `enums.validatorMessage.${fieldState.error.message}` })
                                                            : usernameError
                                                              ? usernameError
                                                              : ""
                                                    }
                                                    error={!!fieldState.error || !_.isNil(usernameError)}
                                                    fullWidth
                                                    label={intl.formatMessage({ id: `pages.accounts.create.form.${name}.label` })}
                                                    hiddenLabel={true}
                                                    variant="outlined"
                                                    value={value}
                                                    onBlur={onBlur}
                                                    onChange={onChange}
                                                    disabled={loading}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid size={6}>
                                        <Controller
                                            name="email"
                                            rules={{ validate: ValidatorService.email }}
                                            control={control}
                                            render={({ field: { onChange, onBlur, value, name }, fieldState }) => (
                                                <TextField
                                                    type={"text"}
                                                    helperText={
                                                        !_.isNil(fieldState.error)
                                                            ? intl.formatMessage({ id: `enums.validatorMessage.${fieldState.error.message}` })
                                                            : emailError
                                                              ? emailError
                                                              : ""
                                                    }
                                                    error={!!fieldState.error || !!emailError}
                                                    fullWidth
                                                    label={intl.formatMessage({ id: `pages.accounts.create.form.${name}.label` })}
                                                    hiddenLabel={true}
                                                    variant="outlined"
                                                    value={value}
                                                    onBlur={onBlur}
                                                    onChange={onChange}
                                                    disabled={loading}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid size={12}>
                                        <Controller
                                            name="name"
                                            rules={{ validate: ValidatorService.required }}
                                            control={control}
                                            render={({ field: { onChange, onBlur, value, name }, fieldState }) => (
                                                <TextField
                                                    type={"text"}
                                                    helperText={!_.isNil(fieldState.error) ? intl.formatMessage({ id: `enums.validatorMessage.${fieldState.error.message}` }) : ""}
                                                    error={!!fieldState.error}
                                                    fullWidth
                                                    label={intl.formatMessage({ id: `pages.accounts.create.form.${name}.label` })}
                                                    hiddenLabel={true}
                                                    variant="outlined"
                                                    value={value}
                                                    onBlur={onBlur}
                                                    onChange={onChange}
                                                    disabled={loading}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid size={6}>
                                        <Controller
                                            name="password"
                                            rules={{
                                                validate: {
                                                    required: ValidatorService.required,
                                                    password: ValidatorService.password,
                                                },
                                            }}
                                            control={control}
                                            render={({ field: { onChange, onBlur, value, name }, fieldState }) => (
                                                <TextField
                                                    type={"password"}
                                                    helperText={!_.isNil(fieldState.error) ? intl.formatMessage({ id: `enums.validatorMessage.${fieldState.error.message}` }) : ""}
                                                    error={!!fieldState.error}
                                                    fullWidth
                                                    label={intl.formatMessage({ id: `pages.accounts.create.form.${name}.label` })}
                                                    hiddenLabel={true}
                                                    variant="outlined"
                                                    value={value}
                                                    onBlur={onBlur}
                                                    onChange={onChange}
                                                    disabled={loading}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid size={6}>
                                        <Controller
                                            name="passwordConfirmation"
                                            rules={{
                                                validate: {
                                                    required: ValidatorService.required,
                                                    match: (value: string) => {
                                                        return value === watch("password") || ValidatorMessage.passwordsDoNotMatch;
                                                    },
                                                },
                                            }}
                                            control={control}
                                            render={({ field: { onChange, onBlur, value, name }, fieldState }) => (
                                                <TextField
                                                    type={"password"}
                                                    helperText={!_.isNil(fieldState.error) ? intl.formatMessage({ id: `enums.validatorMessage.${fieldState.error.message}` }) : ""}
                                                    error={!!fieldState.error}
                                                    fullWidth
                                                    label={intl.formatMessage({ id: `pages.accounts.create.form.${name}.label` })}
                                                    hiddenLabel={true}
                                                    variant="outlined"
                                                    value={value}
                                                    onBlur={onBlur}
                                                    onChange={onChange}
                                                    disabled={loading}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                {!_.isNil(error) && (
                                    <Typography variant={"subtitle1"} fontWeight={600} color={"error"} mt={1}>
                                        {error}
                                    </Typography>
                                )}
                            </Card>
                        </Grid>
                        <Grid size={4}>
                            <Card sx={{ padding: 3 }} variant={"outlined"}>
                                <Grid container spacing={3}>
                                    <Grid size={6}>
                                        <Button variant={"contained"} color={"inherit"} fullWidth={true} size={"large"} disabled={loading}>
                                            {intl.formatMessage({ id: "common.cancel" })}
                                        </Button>
                                    </Grid>
                                    <Grid size={6}>
                                        <Button variant={"contained"} color={"primary"} fullWidth={true} size={"large"} disabled={loading} type={"submit"}>
                                            {intl.formatMessage({ id: "common.save" })}
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Divider sx={{ my: 3 }} />
                                <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                    <Grid size={"auto"}>
                                        <Typography variant={"body2"} fontWeight={600}>
                                            {intl.formatMessage({ id: "pages.accounts.create.form.isActive.label" })}
                                        </Typography>
                                    </Grid>
                                    <Grid size={"auto"}>
                                        <Controller
                                            name={"isActive"}
                                            control={control}
                                            render={({ field: { onChange, onBlur, value, name }, fieldState }) => (
                                                <Switch checked={value} defaultChecked={value} onChange={(_, checked) => onChange(checked)} disabled={loading} />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </PageWrapper>
    );
};
