import React from "react";
import { Box, Typography } from "@mui/material";
import _ from "lodash";

type Props = {
    title?: string | React.ReactElement;
    buttons?: React.ReactElement;
    inputs?: React.ReactElement;
    breadcrumb?: React.ReactElement;
};

export const PageWrapper: React.FC<React.PropsWithChildren<Props>> = ({ title, buttons, inputs, breadcrumb, children }) => {
    return (
        <Box display={"flex"} flexDirection={"column"} sx={{ height: "100%" }}>
            <Box padding={5} style={{ flex: 1 }} display={"flex"} justifyContent={"space-between"}>
                <Box display={"flex"} flexDirection={"column"}>
                    <Typography variant={"h4"} fontWeight={600}>
                        {title}
                    </Typography>
                    {!_.isNil(breadcrumb) && <Box mt={1}>{breadcrumb}</Box>}
                </Box>
                {buttons}
                {inputs}
            </Box>
            <Box sx={{ height: "100%" }}>{children}</Box>
        </Box>
    );
};
