import React from "react";
import { Breadcrumbs as MUIBreadcrumbs, Typography, useTheme } from "@mui/material";
import { BreadcrumbsOwnProps } from "@mui/material/Breadcrumbs/Breadcrumbs";
import { hexToRGBA } from "@theme/Theme";

type Props = {} & BreadcrumbsOwnProps;

export const Breadcrumbs: React.FC<React.PropsWithChildren<Props>> = ({ children, ...props }) => {
    const theme = useTheme();
    return (
        <MUIBreadcrumbs {...props} separator={"/"} sx={{ color: hexToRGBA(theme.palette.primary.main, 0.5) }}>
            {children}
        </MUIBreadcrumbs>
    );
};

export const ActiveBreadcrumbItem: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    return (
        <Typography variant={"body1"} fontWeight={700}>
            {children}
        </Typography>
    );
};
