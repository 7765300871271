import { ApolloClient, ApolloError, DocumentNode, FetchResult, HttpLink, InMemoryCache, Observable } from "@apollo/client";
import { ApiError, ApiErrorCode } from "@api/ApiError";
import { ErrorResponse, onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { store } from "@redux/Store";
import { ObjectUtils } from "@utils/ObjectUtils";
import { GraphQLError } from "graphql";

export type OnProgress = (progress: number) => void;

interface GraphQLFileUploadOptions<V> {
    mutation: DocumentNode;
    variables?: V;
    file: File;
    onProgress?: OnProgress;
}

export class GraphQLClient {
    private static readonly httpLink = new HttpLink({
        uri: process.env.REACT_APP_GRAPHQL_API_URL,
    });

    private static readonly authLink = setContext((_, prevContext) => {
        const authToken = store.getState().auth.accessToken;

        if (!authToken) {
            return prevContext;
        }

        return {
            ...prevContext,
            headers: {
                ...prevContext.headers,
                authorization: `Bearer ${authToken}`,
            },
        };
    });

    protected static readonly errorLink = onError((error: ErrorResponse): Observable<FetchResult> | void => {
        // const refreshToken: string | null = store.getState().auth.refreshToken;

        if (error.response?.errors) {
            console.error(new Error("Response errors"), error.response.errors);
        }

        if (error.response?.errors && error.response?.errors.length > 0) {
            const gqlError = error.response?.errors[0];

            if (gqlError?.extensions?.code === `E_${ApiErrorCode.UNAUTHORIZED}`) {
                /*return new Observable(observer => {
                    GraphQLClient.client
                        .mutate<Gql.refreshTokenMutation, Gql.refreshTokenMutationVariables>({
                            mutation: refreshTokenMutation,
                            variables: { refreshToken: refreshToken || "" },
                        })
                        .then(refreshTokenResponse => {
                            const subscriber = {
                                next: observer.next.bind(observer),
                                error: observer.error.bind(observer),
                                complete: observer.complete.bind(observer),
                            };
                            if (refreshTokenResponse.data?.refreshToken) {
                                const authToken = refreshTokenResponse.data.refreshToken;
                                store.dispatch(AuthActions.setAccessToken(authToken));
                                error.operation.setContext({
                                    headers: {
                                        ...error.operation.getContext().headers,
                                        Authorization: authToken ? `Bearer ${authToken}` : "",
                                    },
                                });
                                error.forward(error.operation).subscribe(subscriber);
                            } else {
                                store.dispatch(AuthActions.logout());
                                error.forward(error.operation).subscribe(subscriber);
                            }
                        })
                        .catch(error => {
                            const subscriber = {
                                next: observer.next.bind(observer),
                                error: observer.error.bind(observer),
                                complete: observer.complete.bind(observer),
                            };
                            console.warn("Token refresh and retry failed", error);
                            store.dispatch(AuthActions.logout());
                            error.forward(error.operation).subscribe(subscriber);
                        });
                });*/
            }
        }

        if (error.networkError) {
            console.error(new Error("Network error"), error.networkError);
        }
    });

    public static readonly client = new ApolloClient({
        link: GraphQLClient.authLink.concat(GraphQLClient.errorLink).concat(GraphQLClient.httpLink),
        cache: new InMemoryCache(),
        defaultOptions: {
            query: {
                fetchPolicy: "network-only",
            },
            mutate: {
                fetchPolicy: "network-only",
            },
        },
    });

    public static upload<R, V>(options: GraphQLFileUploadOptions<V>): Promise<R> {
        return new Promise((resolve: (response: R) => void, reject: (error: ApiError) => void) => {
            const xhr = new XMLHttpRequest();
            const body = new FormData();
            if (!options.mutation.loc) {
                console.error(new Error("options.mutation.loc not found!"));
                reject(new ApiError(ApiErrorCode.INVALID_REQUEST));
            }
            body.append(
                "operations",
                JSON.stringify({
                    query: options.mutation.loc!.source.body,
                    variables: options.variables,
                }),
            );
            body.append("map", JSON.stringify({ 0: ["variables.file"] }));
            body.append("0", options.file);

            xhr.onerror = () => {
                reject(new ApiError(ApiErrorCode.NETWORK_ERROR));
            };

            xhr.ontimeout = () => {
                reject(new ApiError(ApiErrorCode.REQUEST_TIMEOUT));
            };

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    try {
                        const response: { data: R; errors?: GraphQLError[] } = JSON.parse(xhr.response);
                        if (response.errors) {
                            reject(GraphQLClient.getError(new ApolloError({ graphQLErrors: response.errors }))!);
                            return;
                        }
                        resolve(response.data);
                    } catch (error) {
                        reject(new ApiError(ApiErrorCode.INVALID_RESPONSE));
                    }
                }
            };

            xhr.open("POST", process.env.REACT_APP_GRAPHQL_API_URL, true);
            xhr.setRequestHeader("Authorization", `Bearer ${store.getState().auth.accessToken || ""}`);
            xhr.setRequestHeader("Accept", "*/*");

            if (options.onProgress) {
                xhr.upload.onprogress = GraphQLClient.onProgress(options.onProgress);
            }

            xhr.send(body);
        });
    }

    private static onProgress = (onProgressFunction: (progress: number) => void): ((this: XMLHttpRequest, ev: ProgressEvent) => void) => {
        return function (this: XMLHttpRequest, event: ProgressEvent): void {
            onProgressFunction((event.loaded / event.total) * 100);
        };
    };

    public static readonly getError = ({ graphQLErrors, clientErrors, networkError }: ApolloError): ApiError | undefined => {
        if (networkError) {
            return new ApiError(ApiErrorCode.NETWORK_ERROR, networkError);
        }

        if (graphQLErrors.length === 0) {
            return;
        }

        const error = graphQLErrors[0];

        if (error.extensions && error.extensions.hasOwnProperty("code")) {
            const errorCode: string = error.extensions.code as string;

            // Remove 'E_' to convert string to ApiErrorCode
            let code = errorCode.startsWith("E_") ? errorCode.slice(errorCode.indexOf("E_") + 2) : errorCode;

            if (code === ApiErrorCode.BAD_REQUEST) {
                code = error.message.startsWith("E_") ? error.message.slice(error.message.indexOf("E_") + 2) : error.message;
            }

            if (ObjectUtils.isEnumContains<ApiErrorCode>(ApiErrorCode, code)) {
                return new ApiError(code, error);
            } else {
                console.warn("Unknown error code from GraphQL response", code);
            }
        }

        return new ApiError(ApiErrorCode.UNKNOWN, error);
    };
}
