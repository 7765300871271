import React, { useMemo } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, styled, Typography } from "@mui/material";
import { hexToRGBA } from "@theme/Theme";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@redux/Store";
import SvgIconExit from "@components/svg/IconExit";
import { MenuItems } from "@models/Menu";
import _ from "lodash";
import { AuthActions } from "@redux/slices/AuthSlice";
import { useIntl } from "react-intl";

export const DashboardLayout = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { account } = useSelector((state: RootState) => state.auth);
    const intl = useIntl();

    const nameFirstLetter = useMemo(() => {
        const accountNames = (account?.name.split(" ") ?? []).reverse();

        if (accountNames.length > 0) {
            return accountNames[0].charAt(0);
        }

        return "";
    }, [account?.name]);

    const renderMenu = useMemo(() => {
        return MenuItems.map((item, index) => {
            const isActive = !_.isEmpty(item.path) && location.pathname.startsWith(item.path);

            return (
                <ListItem key={`${index}-${item.id}`} sx={{ padding: "4px 16px" }}>
                    <MenuListItemButton sx={{ padding: "8px 12px" }} onClick={() => (item.onClick ? item.onClick() : navigate(item.path))} selected={isActive}>
                        <MenuListItemIcon>{item.icon}</MenuListItemIcon>
                        <MenuListItemText>{intl.formatMessage({ id: `menu.${item.id}` })}</MenuListItemText>
                    </MenuListItemButton>
                </ListItem>
            );
        });
    }, [location.pathname, navigate, intl]);

    return (
        <Wrapper>
            <Drawer variant="permanent" anchor="left" sx={{ flex: 1 }} PaperProps={{ sx: { width: 280 } }}>
                <Box display={"flex"} alignItems={"center"} justifyContent={"start"} p={3}>
                    <LogoIcon display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <LogoText>t</LogoText>
                    </LogoIcon>
                    <Typography variant={"h5"} fontWeight={800} ml={1}>
                        tadamui
                    </Typography>
                </Box>
                <Box
                    sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    }}
                >
                    <List>{renderMenu}</List>
                    <Box p={2}>
                        <Divider />
                        <Box display={"flex"} mt={2} justifyContent={"space-between"} alignItems={"center"}>
                            <Box display={"flex"}>
                                <AccountIcon>
                                    <Typography variant={"body1"} fontSize={24} fontWeight={700}>
                                        {nameFirstLetter}
                                    </Typography>
                                </AccountIcon>
                                <Box ml={1} sx={{ paddingTop: "2px" }}>
                                    <Typography variant={"body1"} fontWeight={600}>
                                        {account?.name}
                                    </Typography>
                                    <Typography variant={"body2"} color={"textSecondary"}>
                                        {account?.username}
                                    </Typography>
                                </Box>
                            </Box>

                            <IconButton color={"primary"} onClick={() => dispatch(AuthActions.logout())}>
                                <SvgIconExit />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            </Drawer>
            <Content>
                <Outlet />
            </Content>
        </Wrapper>
    );
};

const AccountIcon = styled(Box)`
    display: flex;
    width: 48px;
    height: 48px;

    justify-content: center;
    align-items: center;

    border-radius: 24px;

    background-color: ${({ theme }) => hexToRGBA(theme.palette.primary.main, 0.06)};
    border-style: solid;
    border-width: 1px;
    border-color: ${({ theme }) => hexToRGBA(theme.palette.primary.main, 0.12)};
`;

const MenuListItemText = styled(ListItemText)`
    margin-left: 8px;

    span {
        font-weight: 600;
    }
`;

const MenuListItemIcon = styled(ListItemIcon)`
    min-width: unset;

    svg {
        width: auto;
        height: 18px;
        color: #31415a;
    }
`;

const MenuListItemButton = styled(ListItemButton)`
    padding: 8px 12px;

    border-radius: 12px;

    &.Mui-selected {
        background-color: ${({ theme }) => hexToRGBA(theme.palette.primary.main, 0.06)};

        &:hover {
            background-color: ${({ theme }) => hexToRGBA(theme.palette.primary.main, 0.12)};
        }
    }

    &:hover {
        background-color: ${({ theme }) => hexToRGBA(theme.palette.primary.main, 0.12)};
    }
`;

const Content = styled(Stack)`
    width: calc(100vw - 280px);
`;

const LogoText = styled(Typography)`
    font-size: 24px;
    font-weight: 800;
    line-height: 1;
    margin-top: -3px;
`;

const LogoIcon = styled(Box)`
    width: 32px;
    height: 32px;
    background-color: ${({ theme }) => theme.palette.common.black};
    border-radius: 12px;

    color: ${({ theme }) => theme.palette.common.white};
`;

const Wrapper = styled(Stack)`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
`;
