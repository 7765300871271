import { gql } from "@apollo/client";

export const accountFragment = gql`
    fragment Account on Account {
        id
        username
        name
        email
        isActive
        createdAt
        updatedAt
    }
`;

export const authResponseFragment = gql`
    fragment AuthResponse on AuthResponse {
        account {
            ...Account
        }
        authToken
        refreshToken
    }
    ${accountFragment}
`;

export const supplierProductItemFragment = gql`
    fragment SupplierProductItem on SupplierProduct {
        id
        sku
        name
        brand
        description
        createdAt
        updatedAt
    }
`;

export const supplierProductPriceFragment = gql`
    fragment SupplierProductPrice on SupplierProductPrice {
        id
        price
        supplier
        stock
        createdAt
        updatedAt
    }
`;

export const supplierProductFragment = gql`
    fragment SupplierProduct on SupplierProduct {
        id
        sku
        name
        brand
        description
        createdAt
        updatedAt
        prices(listOptions: $listOptions) {
            count
            result {
                ...SupplierProductPrice
            }
        }
    }
    ${supplierProductPriceFragment}
`;
