import { hu } from "date-fns/locale";
import * as DateFns from "date-fns";

export enum DateFormat {
    dateTimeWithoutSec = "yyyy. MM. dd. HH:mm",
    dateTime = "yyyy. MM. dd. HH:mm:ss",
    default = "yyyy. MM. dd.",
    deliveryTime = "yyyy-MM-dd.",
    hourMinute = "HH:mm",
    hourMinuteSec = "HH:mm:ss",
}

export const parseDate = (date: string | Date): Date => {
    if (typeof date === "string") {
        return new Date(date);
    }

    return date;
};

export const formatDate = (date: string | Date, format: DateFormat = DateFormat.default): string => {
    if (typeof date === "string") {
        date = new Date(date);
    }

    return DateFns.format(date, format, { locale: hu });
};
