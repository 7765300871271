import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AuthSelectors } from "@redux/selectors/AuthSelectors";
import { Paths } from "@models/Paths";

type Props = {
    element: React.ReactNode;
};

const GuestRoute = (props: Props) => {
    const navigate = useNavigate();
    const isLoggedIn = useSelector(AuthSelectors.isLoggedIn);

    useEffect(() => {
        if (isLoggedIn) {
            navigate(Paths.dashboard, {
                replace: true,
            });
        }
    }, [isLoggedIn, navigate]);

    return <React.Fragment>{props.element}</React.Fragment>;
};

export default GuestRoute;
