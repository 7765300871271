import React from "react";
import ReactDOM from "react-dom/client";
import "@fontsource/plus-jakarta-sans/200.css";
import "@fontsource/plus-jakarta-sans/300.css";
import "@fontsource/plus-jakarta-sans/400.css";
import "@fontsource/plus-jakarta-sans/500.css";
import "@fontsource/plus-jakarta-sans/600.css";
import "@fontsource/plus-jakarta-sans/700.css";
import "@fontsource/plus-jakarta-sans/800.css";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "@theme/Theme";
import { BrowserRouter } from "react-router-dom";
import { App } from "./App";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "@redux/Store";
import { ApolloProvider } from "@apollo/client";
import { GraphQLClient } from "@api/graphql/GraphQLClient";
import { IntlProvider } from "react-intl";
import { flattenMessages, Language } from "@services/I18nService";
import hu from "@models/translations/hu";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <IntlProvider locale={Language.hu} messages={flattenMessages(hu)}>
            <Provider store={store}>
                <ApolloProvider client={GraphQLClient.client}>
                    <PersistGate persistor={persistor}>
                        <ThemeProvider theme={theme}>
                            <CssBaseline />

                            <BrowserRouter>
                                <App />
                            </BrowserRouter>
                        </ThemeProvider>
                    </PersistGate>
                </ApolloProvider>
            </Provider>
        </IntlProvider>
    </React.StrictMode>,
);
