import React, { useCallback, useEffect, useMemo } from "react";
import { Box, CircularProgress, Table, TableBody, TableCell, TableContainer, TableRow, TableSortLabel, Typography } from "@mui/material";
import { TableOwnProps } from "@mui/material/Table/Table";
import { grey } from "@mui/material/colors";
import { ChevronLeftRounded, ChevronRightRounded } from "@mui/icons-material";
import { isNil } from "lodash";
import { Gql } from "@api/Api";
import { sortOrderToDirection } from "@utils/DataGridHelper";
import {
    DataGridColumnSeparator,
    DataGridData,
    DataGridHeadTableCell,
    DataGridTableHead,
    DataGridTableRow,
    LoadingContainer,
    PaginationButton,
} from "@components/DataGrid/DataGridCommon";
import { useIntl } from "react-intl";

export const DEFAULT_PAGE_SIZE = 10;

interface BaseListOptions<S> {
    sortField?: Gql.InputMaybe<S>;
    control?: Gql.InputMaybe<Gql.ListControl>;
}

export interface DataGridColumn<T, S> {
    flex: number;
    id: string;
    label: string | React.ReactElement;
    renderCell: (row: T) => React.ReactNode;
    sortField?: S;
}

export interface PaginationModel {
    page: number;
    pageSize: number;
}

type Props<T, S, L> = {
    TableProps?: TableOwnProps;
    columns: DataGridColumn<T, S>[];
    data: DataGridData<T>[];
    rowCount: number;
    pageSize?: number;
    initialPaginationModel?: PaginationModel;
    loading?: boolean;
    listOptions: L;
    onListOptionsChange: (options: L) => void;
    onRowClick?: (row: DataGridData<T>) => void;
};

export function DataGrid<T, S, L extends BaseListOptions<S>>(props: Props<T, S, L>) {
    const intl = useIntl();
    const pageSize = props.pageSize ?? 10;
    const [paginationModel, setPaginationModel] = React.useState<PaginationModel>(
        {
            page: props.listOptions.control?.offset ? Math.floor(props.listOptions.control.offset / pageSize) : 0,
            pageSize,
        } ?? props.initialPaginationModel,
    );
    const isLastPage = useMemo(() => paginationModel.pageSize * (paginationModel.page + 1) > props.rowCount, [paginationModel.pageSize, paginationModel.page, props.rowCount]);
    const tableHeight = useMemo(() => paginationModel.pageSize * 53, [paginationModel.pageSize]);

    useEffect(() => {
        props.onListOptionsChange({
            ...props.listOptions,
            control: {
                ...props.listOptions.control,
                limit: paginationModel.pageSize,
                offset: paginationModel.page * paginationModel.pageSize,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationModel]);

    const onSortChange = useCallback(
        (sortField: S) => {
            if (!!props.listOptions.sortField && props.listOptions.control?.sortOrder === Gql.SortOrder.ASC && sortField === props.listOptions.sortField) {
                props.onListOptionsChange({
                    ...props.listOptions,
                    sortField: undefined,
                    control: {
                        ...props.listOptions.control,
                        sortOrder: undefined,
                    },
                });
                return;
            }

            let sortOrder: Gql.SortOrder = props.listOptions.control?.sortOrder === Gql.SortOrder.DESC ? Gql.SortOrder.ASC : Gql.SortOrder.DESC;

            if (sortField !== props.listOptions.sortField) {
                sortOrder = Gql.SortOrder.DESC;
            }

            props.onListOptionsChange({
                ...props.listOptions,
                sortField,
                control: {
                    ...props.listOptions.control,
                    sortOrder,
                },
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props, props.listOptions.control?.sortOrder],
    );

    const TableHeadRenderer = useMemo(() => {
        return (
            <DataGridTableHead>
                <TableRow>
                    {props.columns.map((column, index) => (
                        <DataGridHeadTableCell key={`datagrid-column-${column.id}-${index}`}>
                            {!isNil(column.sortField) ? (
                                <TableSortLabel
                                    onClick={() => onSortChange(column.sortField!)}
                                    active={props.listOptions.sortField === column.sortField}
                                    direction={!!props.listOptions.control?.sortOrder ? sortOrderToDirection(props.listOptions.control.sortOrder) : undefined}
                                    style={{
                                        fontWeight: 600,
                                    }}
                                >
                                    {column.label}
                                </TableSortLabel>
                            ) : (
                                <Typography variant={"subtitle2"} fontWeight={600}>
                                    {column.label}
                                </Typography>
                            )}
                            {index < props.columns.length - 1 && <DataGridColumnSeparator />}
                        </DataGridHeadTableCell>
                    ))}
                </TableRow>
            </DataGridTableHead>
        );
    }, [onSortChange, props.columns, props.listOptions.sortField, props.listOptions.control?.sortOrder]);

    const TableBodyRender = useMemo(() => {
        return (
            <TableBody sx={{ minHeight: tableHeight }}>
                {props.data.map((row, index) => (
                    <DataGridTableRow
                        key={`datagrid-row-${row.id}-${index}`}
                        $clickable={!!props.onRowClick}
                        onClick={() => {
                            props.onRowClick?.(row);
                        }}
                    >
                        {props.columns.map((column, index) => (
                            <TableCell key={`datagrid-row-column-${column.id}-${index}`}>{column.renderCell(row)}</TableCell>
                        ))}
                    </DataGridTableRow>
                ))}
            </TableBody>
        );
    }, [tableHeight, props]);

    return (
        <Box position={"relative"} sx={{ height: "100%" }}>
            <TableContainer
                sx={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                }}
            >
                <Table {...props.TableProps}>
                    {TableHeadRenderer}
                    {TableBodyRender}
                </Table>
                <Box
                    py={1}
                    px={2}
                    width={"100%"}
                    display={"flex"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    sx={{
                        borderTopStyle: "solid",
                        borderTopWidth: paginationModel.pageSize === props.data.length ? 0 : 1,
                        borderColor: grey[300],
                    }}
                >
                    <Typography variant={"subtitle2"} mr={2}>
                        {intl.formatMessage(
                            { id: "components.dataGrid.pagination.total" },
                            {
                                currentRowsFrom: paginationModel.page * paginationModel.pageSize + 1,
                                currentRowsTo: isLastPage ? props.rowCount : (paginationModel.page + 1) * paginationModel.pageSize,
                                totalRows: props.rowCount,
                            },
                        )}
                    </Typography>
                    <PaginationButton
                        disabled={paginationModel.page === 0}
                        onClick={() => {
                            setPaginationModel(value => ({
                                ...value,
                                page: value.page - 1,
                            }));
                        }}
                    >
                        <ChevronLeftRounded />
                    </PaginationButton>
                    <PaginationButton
                        disabled={isLastPage}
                        onClick={() => {
                            setPaginationModel(value => ({
                                ...value,
                                page: value.page + 1,
                            }));
                        }}
                    >
                        <ChevronRightRounded />
                    </PaginationButton>
                </Box>
                {props.loading === true && (
                    <LoadingContainer>
                        <CircularProgress thickness={5} />
                    </LoadingContainer>
                )}
            </TableContainer>
        </Box>
    );
}
