import { gql } from "@apollo/client";
import { accountFragment, supplierProductFragment, supplierProductItemFragment } from "@api/graphql/queries/Fragments";

export const meQuery = gql`
    query me {
        me {
            ...Account
        }
    }
    ${accountFragment}
`;

export const accountListQuery = gql`
    query accountList($listOptions: AccountListOptions) {
        accountList(listOptions: $listOptions) {
            count
            result {
                ...Account
            }
        }
    }
    ${accountFragment}
`;

export const accountQuery = gql`
    query account($id: ID!) {
        account(id: $id) {
            ...Account
        }
    }
    ${accountFragment}
`;

export const supplierProductListQuery = gql`
    query supplierProductListQuery($listOptions: SupplierProductListOptions) {
        supplierProductList(listOptions: $listOptions) {
            count
            result {
                ...SupplierProductItem
            }
        }
    }
    ${supplierProductItemFragment}
`;

export const supplierProductQuery = gql`
    query supplierProduct($id: ID!, $listOptions: SupplierProductPriceListOptions) {
        supplierProduct(id: $id) {
            ...SupplierProduct
        }
    }
    ${supplierProductFragment}
`;
