import React, { useCallback, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Paths } from "@models/Paths";
import GuestRoute from "@components/routes/GuestRoute";
import { LoginPage } from "@pages/auth/LoginPage";
import PrivateRoute from "@components/routes/PrivateRoute";
import { DashboardLayout } from "@components/layouts/DashboardLayout";
import { Redirect } from "@components/Redirect";
import { useLazyQuery } from "@apollo/client";
import { Gql } from "@api/Api";
import { meQuery } from "@api/graphql/queries/Queries";
import { Typography } from "@mui/material";

import { DashboardPage } from "@pages/dashboard/DashboardPage";
import { AccountListPage } from "@pages/accounts/AccountListPage";
import { AccountCreatePage } from "@pages/accounts/AccountCreatePage";
import { isNil } from "lodash";
import { useDispatch } from "react-redux";
import { AuthActions } from "@redux/slices/AuthSlice";
import { AccountEditPage } from "@pages/accounts/AccountEditPage";
import { ProductListPage } from "@pages/products/ProductListPage";

export const App = () => {
    const dispatch = useDispatch();
    const [isApplicationStarted, setIsApplicationStarted] = useState<boolean>(false);
    const [fetchAccount] = useLazyQuery<Gql.meQuery, Gql.meQueryVariables>(meQuery);

    const bootstrapApplication = useCallback(async () => {
        try {
            const { data } = await fetchAccount();

            if (isNil(data)) {
                dispatch(AuthActions.logout());
                setIsApplicationStarted(true);
                return;
            }

            dispatch(AuthActions.setAccount(data.me));
            setIsApplicationStarted(true);
        } catch (error) {
            console.error(error as Error);
            setIsApplicationStarted(false);
        }
    }, [dispatch, fetchAccount]);

    useEffect(() => {
        bootstrapApplication();
    }, [bootstrapApplication]);

    if (!isApplicationStarted) {
        return <Typography variant={"h1"}>Loading...</Typography>;
    }

    return (
        <Routes>
            <Route path={Paths.login} element={<GuestRoute element={<LoginPage />} />} />
            <Route element={<PrivateRoute element={<DashboardLayout />} />}>
                <Route path={Paths.root} element={<Redirect to={Paths.dashboard} />} />

                <Route path={Paths.dashboard} element={<DashboardPage />} />

                <Route path={Paths.accounts} element={<AccountListPage />} />
                <Route path={Paths.accountEdit(":id")} element={<AccountEditPage />} />
                <Route path={Paths.accountCreate} element={<AccountCreatePage />} />

                <Route path={Paths.products} element={<ProductListPage />} />
            </Route>
            <Route path="*" element={<Typography variant={"h1"}>404</Typography>} />p
        </Routes>
    );
};
