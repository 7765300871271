export class ObjectUtils {
    public static keys<T>(obj: T): Array<keyof T> {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return Object.keys(obj).filter((key: string) => key !== "__typename") as Array<keyof T>;
    }

    public static enumAsArray<T>(enumType: object): T[] {
        const attributes: string[] = Object.keys(enumType);
        const keys: string[] = attributes.filter((x: string): boolean => {
            return Number.isNaN(Number.parseInt(x, 10));
        });
        return keys as unknown as T[];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static isEnumContains<T>(enumType: object, value: any): value is T {
        try {
            return ObjectUtils.enumAsArray(enumType).includes(value);
        } catch (error) {
            return false;
        }
    }
}
