import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Base64: { input: string; output: string; }
  Color: { input: string; output: string; }
  Date: { input: string; output: string; }
  DateTime: { input: string; output: string; }
  EmailAddress: { input: string; output: string; }
  Language: { input: string; output: string; }
  Location: { input: string; output: string; }
  Object: { input: string; output: string; }
  PhoneNumber: { input: string; output: string; }
  PostalCode: { input: string; output: string; }
  RegularExpression: { input: string; output: string; }
  Time: { input: string; output: string; }
  URL: { input: string; output: string; }
  Upload: { input: any; output: any; }
  Void: { input: null; output: null; }
};

export type Account = {
  __typename?: 'Account';
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['EmailAddress']['output'];
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  username: Scalars['String']['output'];
};

export type AccountCreateInput = {
  email: Scalars['EmailAddress']['input'];
  isActive: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type AccountList = ListResult & {
  __typename?: 'AccountList';
  count: Scalars['Int']['output'];
  result: Array<Account>;
};

export type AccountListOptions = {
  control?: InputMaybe<ListControl>;
  sortField?: InputMaybe<AccountSortField>;
};

export enum AccountSortField {
  CREATED_AT = 'CREATED_AT',
  EMAIL = 'EMAIL',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT',
  USERNAME = 'USERNAME'
}

export type AccountUpdateInput = {
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  account: Account;
  authToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
};

export type DateTimeRange = {
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
};

export type ListControl = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type ListResult = {
  count: Scalars['Int']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  auth: AuthResponse;
  createAccount: Account;
  logout?: Maybe<Scalars['Void']['output']>;
  ping: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
  updateAccount: Account;
};


export type MutationauthArgs = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationcreateAccountArgs = {
  input: AccountCreateInput;
};


export type MutationrefreshTokenArgs = {
  token: Scalars['String']['input'];
};


export type MutationupdateAccountArgs = {
  id: Scalars['ID']['input'];
  input: AccountUpdateInput;
};

export type Query = {
  __typename?: 'Query';
  account?: Maybe<Account>;
  accountList: AccountList;
  me: Account;
  ping: Scalars['String']['output'];
  supplierProduct?: Maybe<SupplierProduct>;
  supplierProductList: SupplierProductList;
};


export type QueryaccountArgs = {
  id: Scalars['ID']['input'];
};


export type QueryaccountListArgs = {
  listOptions?: InputMaybe<AccountListOptions>;
};


export type QuerysupplierProductArgs = {
  id: Scalars['ID']['input'];
};


export type QuerysupplierProductListArgs = {
  listOptions?: InputMaybe<SupplierProductListOptions>;
};

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum Supplier {
  COMPMARKET = 'COMPMARKET',
  CPL = 'CPL',
  SENETIC = 'SENETIC'
}

export type SupplierProduct = {
  __typename?: 'SupplierProduct';
  brand?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  prices: SupplierProductPriceList;
  sku: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type SupplierProductpricesArgs = {
  listOptions?: InputMaybe<SupplierProductPriceListOptions>;
};

export type SupplierProductList = ListResult & {
  __typename?: 'SupplierProductList';
  count: Scalars['Int']['output'];
  result: Array<SupplierProduct>;
};

export type SupplierProductListOptions = {
  control?: InputMaybe<ListControl>;
  sortField?: InputMaybe<SupplierProductSortField>;
};

export type SupplierProductPrice = {
  __typename?: 'SupplierProductPrice';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  price: Scalars['Float']['output'];
  stock: SupplierProductPriceStock;
  supplier: Supplier;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SupplierProductPriceList = ListResult & {
  __typename?: 'SupplierProductPriceList';
  count: Scalars['Int']['output'];
  result: Array<SupplierProductPrice>;
};

export type SupplierProductPriceListOptions = {
  control?: InputMaybe<ListControl>;
  sortField?: InputMaybe<SupplierProductPriceSortField>;
};

export enum SupplierProductPriceSortField {
  CREATED_AT = 'CREATED_AT',
  ID = 'ID',
  PRICE = 'PRICE',
  STOCK = 'STOCK',
  UPDATED_AT = 'UPDATED_AT'
}

export enum SupplierProductPriceStock {
  IN_STOCK = 'IN_STOCK',
  OUT_OF_STOCK = 'OUT_OF_STOCK'
}

export enum SupplierProductSortField {
  BRAND = 'BRAND',
  CREATED_AT = 'CREATED_AT',
  ID = 'ID',
  NAME = 'NAME',
  SKU = 'SKU',
  UPDATED_AT = 'UPDATED_AT'
}

export type AccountFragment = { __typename?: 'Account', id: string, username: string, name: string, email: string, isActive: boolean, createdAt: string, updatedAt?: string | null };

export type AuthResponseFragment = { __typename?: 'AuthResponse', authToken: string, refreshToken: string, account: { __typename?: 'Account', id: string, username: string, name: string, email: string, isActive: boolean, createdAt: string, updatedAt?: string | null } };

export type SupplierProductItemFragment = { __typename?: 'SupplierProduct', id: string, sku: string, name: string, brand?: string | null, description?: string | null, createdAt: string, updatedAt?: string | null };

export type SupplierProductPriceFragment = { __typename?: 'SupplierProductPrice', id: string, price: number, supplier: Supplier, stock: SupplierProductPriceStock, createdAt: string, updatedAt?: string | null };

export type SupplierProductFragment = { __typename?: 'SupplierProduct', id: string, sku: string, name: string, brand?: string | null, description?: string | null, createdAt: string, updatedAt?: string | null, prices: { __typename?: 'SupplierProductPriceList', count: number, result: Array<{ __typename?: 'SupplierProductPrice', id: string, price: number, supplier: Supplier, stock: SupplierProductPriceStock, createdAt: string, updatedAt?: string | null }> } };

export type authMutationVariables = Exact<{
  username: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type authMutation = { __typename?: 'Mutation', auth: { __typename?: 'AuthResponse', authToken: string, refreshToken: string, account: { __typename?: 'Account', id: string, username: string, name: string, email: string, isActive: boolean, createdAt: string, updatedAt?: string | null } } };

export type logoutMutationVariables = Exact<{ [key: string]: never; }>;


export type logoutMutation = { __typename?: 'Mutation', logout?: null | null };

export type refreshTokenMutationVariables = Exact<{
  refreshToken: Scalars['String']['input'];
}>;


export type refreshTokenMutation = { __typename?: 'Mutation', refreshToken: string };

export type createAccountMutationVariables = Exact<{
  input: AccountCreateInput;
}>;


export type createAccountMutation = { __typename?: 'Mutation', createAccount: { __typename?: 'Account', id: string } };

export type updateAccountMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: AccountUpdateInput;
}>;


export type updateAccountMutation = { __typename?: 'Mutation', updateAccount: { __typename?: 'Account', id: string } };

export type meQueryVariables = Exact<{ [key: string]: never; }>;


export type meQuery = { __typename?: 'Query', me: { __typename?: 'Account', id: string, username: string, name: string, email: string, isActive: boolean, createdAt: string, updatedAt?: string | null } };

export type accountListQueryVariables = Exact<{
  listOptions?: InputMaybe<AccountListOptions>;
}>;


export type accountListQuery = { __typename?: 'Query', accountList: { __typename?: 'AccountList', count: number, result: Array<{ __typename?: 'Account', id: string, username: string, name: string, email: string, isActive: boolean, createdAt: string, updatedAt?: string | null }> } };

export type accountQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type accountQuery = { __typename?: 'Query', account?: { __typename?: 'Account', id: string, username: string, name: string, email: string, isActive: boolean, createdAt: string, updatedAt?: string | null } | null };

export type supplierProductListQueryQueryVariables = Exact<{
  listOptions?: InputMaybe<SupplierProductListOptions>;
}>;


export type supplierProductListQueryQuery = { __typename?: 'Query', supplierProductList: { __typename?: 'SupplierProductList', count: number, result: Array<{ __typename?: 'SupplierProduct', id: string, sku: string, name: string, brand?: string | null, description?: string | null, createdAt: string, updatedAt?: string | null }> } };

export type supplierProductQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  listOptions?: InputMaybe<SupplierProductPriceListOptions>;
}>;


export type supplierProductQuery = { __typename?: 'Query', supplierProduct?: { __typename?: 'SupplierProduct', id: string, sku: string, name: string, brand?: string | null, description?: string | null, createdAt: string, updatedAt?: string | null, prices: { __typename?: 'SupplierProductPriceList', count: number, result: Array<{ __typename?: 'SupplierProductPrice', id: string, price: number, supplier: Supplier, stock: SupplierProductPriceStock, createdAt: string, updatedAt?: string | null }> } } | null };

export const AccountFragmentDoc = gql`
    fragment Account on Account {
  id
  username
  name
  email
  isActive
  createdAt
  updatedAt
}
    `;
export const AuthResponseFragmentDoc = gql`
    fragment AuthResponse on AuthResponse {
  account {
    ...Account
  }
  authToken
  refreshToken
}
    ${AccountFragmentDoc}`;
export const SupplierProductItemFragmentDoc = gql`
    fragment SupplierProductItem on SupplierProduct {
  id
  sku
  name
  brand
  description
  createdAt
  updatedAt
}
    `;
export const SupplierProductPriceFragmentDoc = gql`
    fragment SupplierProductPrice on SupplierProductPrice {
  id
  price
  supplier
  stock
  createdAt
  updatedAt
}
    `;
export const SupplierProductFragmentDoc = gql`
    fragment SupplierProduct on SupplierProduct {
  id
  sku
  name
  brand
  description
  createdAt
  updatedAt
  prices(listOptions: $listOptions) {
    count
    result {
      ...SupplierProductPrice
    }
  }
}
    ${SupplierProductPriceFragmentDoc}`;
export const authDocument = gql`
    mutation auth($username: String!, $password: String!) {
  auth(username: $username, password: $password) {
    ...AuthResponse
  }
}
    ${AuthResponseFragmentDoc}`;
export type authMutationFn = Apollo.MutationFunction<authMutation, authMutationVariables>;
export type authMutationResult = Apollo.MutationResult<authMutation>;
export type authMutationOptions = Apollo.BaseMutationOptions<authMutation, authMutationVariables>;
export const logoutDocument = gql`
    mutation logout {
  logout
}
    `;
export type logoutMutationFn = Apollo.MutationFunction<logoutMutation, logoutMutationVariables>;
export type logoutMutationResult = Apollo.MutationResult<logoutMutation>;
export type logoutMutationOptions = Apollo.BaseMutationOptions<logoutMutation, logoutMutationVariables>;
export const refreshTokenDocument = gql`
    mutation refreshToken($refreshToken: String!) {
  refreshToken(token: $refreshToken)
}
    `;
export type refreshTokenMutationFn = Apollo.MutationFunction<refreshTokenMutation, refreshTokenMutationVariables>;
export type refreshTokenMutationResult = Apollo.MutationResult<refreshTokenMutation>;
export type refreshTokenMutationOptions = Apollo.BaseMutationOptions<refreshTokenMutation, refreshTokenMutationVariables>;
export const createAccountDocument = gql`
    mutation createAccount($input: AccountCreateInput!) {
  createAccount(input: $input) {
    id
  }
}
    `;
export type createAccountMutationFn = Apollo.MutationFunction<createAccountMutation, createAccountMutationVariables>;
export type createAccountMutationResult = Apollo.MutationResult<createAccountMutation>;
export type createAccountMutationOptions = Apollo.BaseMutationOptions<createAccountMutation, createAccountMutationVariables>;
export const updateAccountDocument = gql`
    mutation updateAccount($id: ID!, $input: AccountUpdateInput!) {
  updateAccount(id: $id, input: $input) {
    id
  }
}
    `;
export type updateAccountMutationFn = Apollo.MutationFunction<updateAccountMutation, updateAccountMutationVariables>;
export type updateAccountMutationResult = Apollo.MutationResult<updateAccountMutation>;
export type updateAccountMutationOptions = Apollo.BaseMutationOptions<updateAccountMutation, updateAccountMutationVariables>;
export const meDocument = gql`
    query me {
  me {
    ...Account
  }
}
    ${AccountFragmentDoc}`;
export type meQueryResult = Apollo.QueryResult<meQuery, meQueryVariables>;
export const accountListDocument = gql`
    query accountList($listOptions: AccountListOptions) {
  accountList(listOptions: $listOptions) {
    count
    result {
      ...Account
    }
  }
}
    ${AccountFragmentDoc}`;
export type accountListQueryResult = Apollo.QueryResult<accountListQuery, accountListQueryVariables>;
export const accountDocument = gql`
    query account($id: ID!) {
  account(id: $id) {
    ...Account
  }
}
    ${AccountFragmentDoc}`;
export type accountQueryResult = Apollo.QueryResult<accountQuery, accountQueryVariables>;
export const supplierProductListQueryDocument = gql`
    query supplierProductListQuery($listOptions: SupplierProductListOptions) {
  supplierProductList(listOptions: $listOptions) {
    count
    result {
      ...SupplierProductItem
    }
  }
}
    ${SupplierProductItemFragmentDoc}`;
export type supplierProductListQueryQueryResult = Apollo.QueryResult<supplierProductListQueryQuery, supplierProductListQueryQueryVariables>;
export const supplierProductDocument = gql`
    query supplierProduct($id: ID!, $listOptions: SupplierProductPriceListOptions) {
  supplierProduct(id: $id) {
    ...SupplierProduct
  }
}
    ${SupplierProductFragmentDoc}`;
export type supplierProductQueryResult = Apollo.QueryResult<supplierProductQuery, supplierProductQueryVariables>;