import { Gql } from "@api/Api";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AuthReducerState {
    accessToken: string | null;
    refreshToken: string | null;
    account: Gql.AccountFragment | null;
}

const initialState: AuthReducerState = {
    accessToken: null,
    refreshToken: null,
    account: null,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAccessToken(state, action: PayloadAction<string | null>) {
            state.accessToken = action.payload;
        },
        setRefreshToken(state, action: PayloadAction<string | null>) {
            state.refreshToken = action.payload;
        },
        setAccount(state, action: PayloadAction<Gql.AccountFragment | null>) {
            state.account = action.payload;
        },
        logout() {
            return { ...initialState };
        },
    },
});

export const AuthActions = authSlice.actions;

export const AuthReducer = authSlice.reducer;
