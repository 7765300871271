import React, { useCallback, useEffect, useMemo, useState } from "react";
import { PageWrapper } from "@components/PageWrapper";
import { useIntl } from "react-intl";
import { Configure, InstantSearch, useHits, usePagination, useSearchBox, useSortBy } from "react-instantsearch";
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import { Box, Card, InputAdornment, TextField, Typography } from "@mui/material";
import SvgIconSearch from "@components/svg/IconSearch";
import { Gql } from "@api/Api";
import { Hit } from "instantsearch.js/es/types/results";
import { DataGrid, DataGridColumn, DEFAULT_PAGE_SIZE } from "@components/DataGrid/DataGrid";
import { DateFormat, formatDate } from "@utils/DateUtils";
import { DefaultNullValue } from "@models/Consts";

export const ProductListPage = () => {
    const { searchClient } = instantMeiliSearch(process.env.REACT_APP_MEILI_HOST, process.env.REACT_APP_MEILI_KEY);

    return (
        <InstantSearch indexName={process.env.REACT_APP_MEILI_PRODUCT_INDEX} searchClient={searchClient}>
            <Configure hitsPerPage={DEFAULT_PAGE_SIZE} />
            <ProductListPageInner />
        </InstantSearch>
    );
};

type ProductHit = Hit<{
    id: number;
    sku: string;
    name: string;
    brand: string;
    description: string;
    createdAt: string;
    updatedAt: string;
    supplier: string;
    price: number;
    stock: Gql.SupplierProductPriceStock;
}>;

export enum ProductSortField {
    supplier = "supplier",
    stock = "stock",
    price = "price",
}

type ProductListOptions = {
    control?: Gql.ListControl;
    sortField?: ProductSortField;
};

const ProductListPageInner = () => {
    const intl = useIntl();
    const { items: data } = useHits<ProductHit>();
    const { query, refine } = useSearchBox();
    const pagination = usePagination();
    const { refine: sortRefine } = useSortBy({
        items: [
            { label: "Default", value: "production_supplier_product" },
            { label: "Price ASC", value: "production_supplier_product:price:asc" },
            { label: "Price DESC", value: "production_supplier_product:price:desc" },
            { label: "Stock ASC", value: "production_supplier_product:stock:asc" },
            { label: "Stock DESC", value: "production_supplier_product:stock:desc" },
            { label: "Supplier ASC", value: "production_supplier_product:supplier:asc" },
            { label: "Supplier DESC", value: "production_supplier_product:supplier:desc" },
        ],
    });

    useEffect(() => {
        console.log({ pagination });
    }, [pagination]);

    const [listOptions, setListOptions] = useState<ProductListOptions>({
        control: {
            limit: DEFAULT_PAGE_SIZE,
            offset: pagination.currentRefinement * DEFAULT_PAGE_SIZE,
        },
    });

    useEffect(() => {
        setListOptions(prev => ({
            ...prev,
            control: {
                ...prev.control,
                limit: DEFAULT_PAGE_SIZE,
                offset: pagination.currentRefinement * DEFAULT_PAGE_SIZE,
            },
        }));
    }, [pagination.currentRefinement]);

    const columns = useMemo((): DataGridColumn<ProductHit, ProductSortField>[] => {
        return [
            {
                id: "id",
                label: intl.formatMessage({ id: "pages.products.list.table.columns.id" }),
                flex: 1,
                renderCell: (row: ProductHit) => {
                    return <Typography variant={"body2"}>{row.id}</Typography>;
                },
            },
            {
                id: "sku",
                label: intl.formatMessage({ id: "pages.products.list.table.columns.sku" }),
                flex: 1,
                renderCell: (row: ProductHit) => {
                    return <Typography variant={"body2"}>{row.sku}</Typography>;
                },
            },
            {
                id: "supplier",
                label: intl.formatMessage({ id: "pages.products.list.table.columns.supplier" }),
                flex: 1,
                renderCell: (row: ProductHit) => {
                    return <Typography variant={"body2"}>{intl.formatMessage({ id: `enums.supplier.${(row.supplier ?? "").toUpperCase()}` })}</Typography>;
                },
                sortField: ProductSortField.supplier,
            },
            {
                id: "name",
                label: intl.formatMessage({ id: "pages.products.list.table.columns.name" }),
                flex: 1,
                renderCell: (row: ProductHit) => {
                    return <Typography variant={"body2"}>{row.name}</Typography>;
                },
            },
            {
                id: "price",
                label: intl.formatMessage({ id: "pages.products.list.table.columns.price" }),
                flex: 1,
                renderCell: (row: ProductHit) => {
                    return (
                        <Typography variant={"body2"}>
                            {intl.formatNumber(row.price, {
                                currency: "HUF",
                                useGrouping: true,
                                style: "currency",
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            })}
                        </Typography>
                    );
                },
                sortField: ProductSortField.price,
            },
            {
                id: "stock",
                label: intl.formatMessage({ id: "pages.products.list.table.columns.stock" }),
                flex: 1,
                renderCell: (row: ProductHit) => {
                    return <Typography variant={"body2"}>{intl.formatMessage({ id: `enums.stock.${row.stock}` })}</Typography>;
                },
                sortField: ProductSortField.stock,
            },
            {
                id: "brand",
                label: intl.formatMessage({ id: "pages.products.list.table.columns.brand" }),
                flex: 1,
                renderCell: (row: ProductHit) => {
                    return <Typography variant={"body2"}>{row.brand}</Typography>;
                },
            },
            {
                id: "createdAt",
                label: intl.formatMessage({ id: "pages.products.list.table.columns.createdAt" }),
                flex: 1,
                renderCell: (row: ProductHit) => {
                    return <Typography variant={"body2"}>{formatDate(row.createdAt, DateFormat.dateTime)}</Typography>;
                },
            },
            {
                id: "updatedAt",
                label: intl.formatMessage({ id: "pages.products.list.table.columns.updatedAt" }),
                flex: 1,
                renderCell: (row: ProductHit) => {
                    return <Typography variant={"body2"}>{row.updatedAt ? formatDate(row.createdAt, DateFormat.dateTime) : DefaultNullValue}</Typography>;
                },
            },
        ];
    }, [intl]);

    const setSort = useCallback(
        (listOptions: ProductListOptions) => {
            if (!!listOptions.sortField) {
                sortRefine(`production_supplier_product:${listOptions.sortField}:${listOptions.control?.sortOrder === "DESC" ? "desc" : "asc"}`);
            } else {
                sortRefine("production_supplier_product");
            }
            setListOptions(listOptions);
        },
        [sortRefine],
    );

    return (
        <PageWrapper
            title={intl.formatMessage({ id: "pages.products.list.title" })}
            inputs={
                <Box>
                    <TextField
                        size={"small"}
                        type={"search"}
                        placeholder={intl.formatMessage({ id: "common.search" })}
                        slotProps={{
                            input: {
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SvgIconSearch />
                                    </InputAdornment>
                                ),
                            },
                        }}
                        value={query}
                        onChange={e => refine(e.target.value)}
                    />
                </Box>
            }
        >
            <Box p={5} pt={0} sx={{ height: "100%" }}>
                <Card sx={{ height: "100%" }} variant={"outlined"}>
                    <DataGrid
                        columns={columns}
                        data={data}
                        rowCount={pagination.nbHits}
                        loading={false}
                        listOptions={listOptions}
                        onListOptionsChange={e => {
                            setSort(e);
                            pagination.refine((e.control?.offset ?? 0) / (e.control?.limit ?? DEFAULT_PAGE_SIZE));
                        }}
                    />
                </Card>
            </Box>
        </PageWrapper>
    );
};
