import React from "react";
import { useSelector } from "react-redux";
import { AuthSelectors } from "@redux/selectors/AuthSelectors";
import { Redirect } from "@components/Redirect";
import { Paths } from "@models/Paths";

type Props = {
    element: React.ReactElement;
};

const PrivateRoute = (props: Props) => {
    const isLoggedIn = useSelector(AuthSelectors.isLoggedIn);

    if (!isLoggedIn) {
        return <Redirect to={Paths.login} options={{ replace: true }} />;
    }

    return props.element;
};

export default PrivateRoute;
