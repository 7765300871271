import * as React from "react";
import { SVGProps } from "react";
const SvgIconFamilyHeart = (props: SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" focusable={false} {...props}>
        <path d="M9.876 6.11a1.236 1.236 0 0 1 1.747-1.748l.376.376.376-.376a1.236 1.236 0 0 1 1.748 1.748l-1.728 1.726a.56.56 0 0 1-.793 0L9.876 6.11Z" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.41 9.208a2.744 2.744 0 1 1 5.487 0 2.744 2.744 0 0 1-5.487 0Zm2.744-1.243a1.244 1.244 0 1 0 0 2.487 1.244 1.244 0 0 0 0-2.487Z"
        />
        <path d="M2 16.716a3.747 3.747 0 0 1 3.747-3.747H8.56l.105.001v.012c0 .555.135 1.078.375 1.538a2.255 2.255 0 0 0-.48-.051H5.747A2.247 2.247 0 0 0 3.5 16.716c0 .137.111.249.249.249h3.696a4.948 4.948 0 0 0-.397 1.5H3.75A1.749 1.749 0 0 1 2 16.715ZM16.953 18.465a4.948 4.948 0 0 0-.396-1.5h3.694a.249.249 0 0 0 .249-.25 2.247 2.247 0 0 0-2.247-2.246h-2.812c-.164 0-.324.017-.478.05.24-.46.375-.982.375-1.537v-.012l.103-.001h2.812A3.747 3.747 0 0 1 22 16.716c0 .966-.783 1.749-1.749 1.749h-3.298Z" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.848 6.465a2.744 2.744 0 1 0 0 5.487 2.744 2.744 0 0 0 0-5.487Zm-1.244 2.743a1.244 1.244 0 1 1 2.488 0 1.244 1.244 0 0 1-2.488 0ZM11.15 15.496a2.628 2.628 0 0 0-2.627 2.628c0 .76.616 1.376 1.375 1.376h4.206c.76 0 1.376-.616 1.376-1.376a2.628 2.628 0 0 0-2.628-2.628H11.15ZM13.973 18h-3.944a1.128 1.128 0 0 1 1.121-1.004h1.702c.58 0 1.059.44 1.12 1.004Z"
        />
        <path d="M10.251 12.98a1.75 1.75 0 1 1 3.5 0 1.75 1.75 0 0 1-3.5 0Z" />
    </svg>
);
export default SvgIconFamilyHeart;
