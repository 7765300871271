enum ValidatorMessage {
    required = "required",
    greaterThanZero = "greaterThanZero",
    invalidEmail = "invalidEmail",
    invalidUsername = "invalidUsername",
    weakPassword = "weakPassword",
    passwordsDoNotMatch = "passwordsDoNotMatch",
}

class ValidatorConstants {
    public static readonly EMAIL_REGEX: RegExp =
        /(?:[a-z0-9!#$%&'+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

    public static readonly PHONE_REGEX: RegExp = /((?:\+?3|0)6)(?:-|\()?(\d{1,2})(?:-|\))?(\d{3})-?(\d{3,4})/;
}

class ValidatorService {
    public static required<T>(value?: T | null): ValidatorMessage | undefined {
        if (!value) {
            return ValidatorMessage.required;
        }
        return undefined;
    }

    public static greaterThanZero(value: number): ValidatorMessage | undefined {
        if (value <= 0) {
            return ValidatorMessage.greaterThanZero;
        }
        return undefined;
    }

    /**
     * Validate email by regex
     * @param email email
     */
    public static email(email: string): ValidatorMessage | undefined {
        if (!email.trim() || !ValidatorConstants.EMAIL_REGEX.test(email)) {
            return ValidatorService.required(email) ?? ValidatorMessage.invalidEmail;
        }
        return undefined;
    }

    public static username(username: string): ValidatorMessage | undefined {
        if (username.trim().length < 3) {
            return ValidatorMessage.invalidUsername;
        }
        return undefined;
    }

    public static password(password: string): ValidatorMessage | undefined {
        if (password.trim().length < 8) {
            return ValidatorMessage.weakPassword;
        }
        return undefined;
    }
}

export { ValidatorService, ValidatorConstants, ValidatorMessage };
