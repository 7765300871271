import React from "react";
import { Link as MUILink } from "@mui/material";
import { Link as ReactRouterLink, LinkProps } from "react-router-dom";
import { TypographyOwnProps } from "@mui/material/Typography";

type Props = {
    variant?: TypographyOwnProps["variant"];
    color?: TypographyOwnProps["color"] | "inherit";
    //color?: "initial" | "inherit" | "primary" | "secondary" | "textPrimary" | "textSecondary" | "error";
} & LinkProps;

export const Link: React.FC<Props> = ({ children, color, ...props }) => {
    return (
        <MUILink component={ReactRouterLink} {...props} underline={"hover"} color={color ?? "inherit"}>
            {children}
        </MUILink>
    );
};
