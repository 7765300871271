import React, { useState } from "react";
import { Box, Button, Card, Stack, styled, TextField, Typography } from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { ValidatorService } from "@services/ValidatorService";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useMutation } from "@apollo/client";
import { Gql } from "@api/Api";
import { authMutation } from "@api/graphql/queries/Mutations";
import { AuthActions } from "@redux/slices/AuthSlice";
import { Paths } from "@models/Paths";
import { GraphQLClient } from "@api/graphql/GraphQLClient";
import * as _ from "lodash";
import { DirectionsWalkRounded } from "@mui/icons-material";
import { useIntl } from "react-intl";

type FormValues = {
    username: string;
    password: string;
};

export const LoginPage = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [error, setError] = useState<string | React.ReactElement | null>(null);
    const [authFn, { loading: authLoading }] = useMutation<Gql.authMutation, Gql.authMutationVariables>(authMutation, {
        onCompleted: data => {
            setError(null);
            dispatch(AuthActions.setAccessToken(data.auth.authToken));
            dispatch(AuthActions.setRefreshToken(data.auth.refreshToken));
            dispatch(AuthActions.setAccount(data.auth.account));
            navigate(Paths.root);
        },
        onError: error => {
            const apiError = GraphQLClient.getError(error);

            if (!_.isNil(apiError)) {
                setError(intl.formatMessage({ id: apiError.i18nKey }));
            }
        },
    });

    const { control, handleSubmit } = useForm<FormValues>({
        defaultValues: {
            username: "",
            password: "",
        },
        mode: "onSubmit",
    });

    const onSubmit: SubmitHandler<FormValues> = async (values: FormValues): Promise<void> => {
        await authFn({
            variables: {
                username: values.username,
                password: values.password,
            },
        });
    };

    return (
        <LoginWrapper direction={"column"} justifyContent={"center"} alignItems={"center"}>
            <LoginCard variant="outlined">
                <Box padding={3}>
                    <Typography component="h1" variant="h4" color={"primary"} sx={{ width: "100%", fontWeight: 800 }} pb={3}>
                        {intl.formatMessage({ id: "pages.auth.login.title" })}
                    </Typography>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 3 }}>
                            <Controller
                                name="username"
                                rules={{ validate: ValidatorService.required }}
                                control={control}
                                render={({ field: { onChange, onBlur, value }, fieldState }) => (
                                    <TextField
                                        type={"text"}
                                        helperText={
                                            !_.isNil(fieldState.error) ? intl.formatMessage({ id: `enums.validatorMessage.${fieldState.error.message}` }) : error ? error : ""
                                        }
                                        error={!!fieldState.error || !_.isNil(error)}
                                        fullWidth
                                        label={intl.formatMessage({ id: "pages.auth.login.form.username.label" })}
                                        hiddenLabel={true}
                                        variant="outlined"
                                        value={value}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        disabled={authLoading}
                                    />
                                )}
                            />

                            <Controller
                                name="password"
                                rules={{ validate: ValidatorService.required }}
                                control={control}
                                render={({ field: { onChange, onBlur, value }, fieldState }) => (
                                    <TextField
                                        type={"password"}
                                        helperText={!_.isNil(fieldState.error) ? intl.formatMessage({ id: `enums.validatorMessage.${fieldState.error.message}` }) : ""}
                                        error={!!fieldState.error || !_.isNil(error)}
                                        fullWidth
                                        label={intl.formatMessage({ id: "pages.auth.login.form.password.label" })}
                                        variant="outlined"
                                        value={value}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        disabled={authLoading}
                                    />
                                )}
                            />
                        </Box>

                        <Box pt={3}>
                            <Button variant={"contained"} color={"primary"} fullWidth={true} size={"large"} type={"submit"} startIcon={<DirectionsWalkRounded />}>
                                {intl.formatMessage({ id: "pages.auth.login.title" })}
                            </Button>
                        </Box>
                    </form>
                </Box>
            </LoginCard>
        </LoginWrapper>
    );
};

const LoginCard = styled(Card)`
    width: 450px;

    @media (max-width: 600px) {
        width: 100%;
    }
`;

const LoginWrapper = styled(Stack)`
    min-height: 100vh;
    padding: 32px;
`;
