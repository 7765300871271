export enum Language {
    hu = "hu",
}

export type Translation = {
    [key: string]: string | Translation;
};

export type I18nMessageObject = {
    [key: string]: string;
};

export const flattenMessages = (nestedMessages: Translation, prefix = ""): I18nMessageObject => {
    return Object.keys(nestedMessages).reduce((messages: I18nMessageObject, key: string) => {
        const value: Translation | string = nestedMessages[key];
        const prefixedKey: string = prefix ? `${prefix}.${key}` : key;

        if (typeof value === "string") {
            messages[prefixedKey] = value;
        } else {
            Object.assign(messages, flattenMessages(value, prefixedKey));
        }

        return messages;
    }, {});
};
