import React, { useMemo, useState } from "react";
import { Box, Button, Card, Typography } from "@mui/material";
import { PageWrapper } from "@components/PageWrapper";
import { useQuery } from "@apollo/client";
import { Gql } from "@api/Api";
import { accountListQuery } from "@api/graphql/queries/Queries";
import { DataGrid, DataGridColumn, DEFAULT_PAGE_SIZE } from "@components/DataGrid/DataGrid";
import { useIntl } from "react-intl";
import { DateFormat, formatDate } from "@utils/DateUtils";
import { DefaultNullValue } from "@models/Consts";
import { useNavigate } from "react-router-dom";
import { Paths } from "@models/Paths";

export const AccountListPage = () => {
    const intl = useIntl();
    const navigate = useNavigate();

    const [listOptions, setListOptions] = useState<Gql.AccountListOptions>({
        sortField: Gql.AccountSortField.CREATED_AT,
        control: {
            sortOrder: Gql.SortOrder.DESC,
            limit: DEFAULT_PAGE_SIZE,
            offset: 0,
        },
    });
    const { data, previousData, loading } = useQuery<Gql.accountListQuery, Gql.accountListQueryVariables>(accountListQuery, {
        variables: {
            listOptions: listOptions,
        },
        fetchPolicy: "network-only",
    });

    const columns = useMemo((): DataGridColumn<Gql.AccountFragment, Gql.AccountSortField>[] => {
        return [
            {
                id: "id",
                label: intl.formatMessage({ id: "pages.accounts.list.table.columns.id" }),
                flex: 1,
                renderCell: (row: Gql.AccountFragment) => {
                    return <Typography variant={"body2"}>{row.id}</Typography>;
                },
            },
            {
                id: "username",
                label: intl.formatMessage({ id: "pages.accounts.list.table.columns.username" }),
                flex: 1,
                renderCell: (row: Gql.AccountFragment) => {
                    return <Typography variant={"body2"}>{row.username}</Typography>;
                },
                sortField: Gql.AccountSortField.USERNAME,
            },
            {
                id: "email",
                label: intl.formatMessage({ id: "pages.accounts.list.table.columns.email" }),
                flex: 1,
                renderCell: (row: Gql.AccountFragment) => {
                    return <Typography variant={"body2"}>{row.email}</Typography>;
                },
                sortField: Gql.AccountSortField.EMAIL,
            },
            {
                id: "name",
                label: intl.formatMessage({ id: "pages.accounts.list.table.columns.name" }),
                flex: 1,
                renderCell: (row: Gql.AccountFragment) => {
                    return <Typography variant={"body2"}>{row.name}</Typography>;
                },
                sortField: Gql.AccountSortField.NAME,
            },
            {
                id: "createdAt",
                label: intl.formatMessage({ id: "pages.accounts.list.table.columns.createdAt" }),
                flex: 1,
                renderCell: (row: Gql.AccountFragment) => {
                    return <Typography variant={"body2"}>{formatDate(row.createdAt, DateFormat.dateTime)}</Typography>;
                },
                sortField: Gql.AccountSortField.CREATED_AT,
            },
            {
                id: "updatedAt",
                label: intl.formatMessage({ id: "pages.accounts.list.table.columns.updatedAt" }),
                flex: 1,
                renderCell: (row: Gql.AccountFragment) => {
                    return <Typography variant={"body2"}>{row.updatedAt ? formatDate(row.createdAt, DateFormat.dateTime) : DefaultNullValue}</Typography>;
                },
                sortField: Gql.AccountSortField.UPDATED_AT,
            },
        ];
    }, [intl]);

    return (
        <PageWrapper
            title={intl.formatMessage({ id: "pages.accounts.list.title" })}
            buttons={
                <Box>
                    <Button variant={"contained"} color={"primary"} onClick={() => navigate(Paths.accountCreate)}>
                        {intl.formatMessage({ id: "pages.accounts.list.buttons.create" })}
                    </Button>
                </Box>
            }
        >
            <Box p={5} pt={0} sx={{ height: "100%" }}>
                <Card sx={{ height: "100%" }} variant={"outlined"}>
                    <DataGrid
                        columns={columns}
                        data={data?.accountList.result ?? previousData?.accountList.result ?? []}
                        rowCount={data?.accountList.count ?? previousData?.accountList.count ?? 0}
                        loading={loading}
                        listOptions={listOptions}
                        onListOptionsChange={setListOptions}
                        onRowClick={row => navigate(Paths.accountEdit(row.id))}
                    />
                </Card>
            </Box>
        </PageWrapper>
    );
};
