import type { GraphQLFormattedError } from "graphql/index";

class ApiError extends Error {
    public readonly i18nKey: string;
    public readonly code: ApiErrorCode;
    public readonly graphQLError?: GraphQLFormattedError;

    constructor(message: ApiErrorCode, graphQLError?: GraphQLFormattedError) {
        super(message);
        this.i18nKey = `errors.api.${message}`;
        this.code = message;
        this.graphQLError = graphQLError;
    }
}

enum ApiErrorCode {
    UNKNOWN = "UNKNOWN",
    UNAUTHORIZED = "UNAUTHORIZED",
    UNAUTHENTICATED = "UNAUTHENTICATED",
    INVALID_REQUEST = "INVALID_REQUEST",
    INVALID_RESPONSE = "INVALID_RESPONSE",
    NETWORK_ERROR = "NETWORK_ERROR",
    REQUEST_TIMEOUT = "REQUEST_TIMEOUT",
    NOT_ALLOWED = "NOT_ALLOWED",
    FORBIDDEN = "FORBIDDEN",
    BAD_REQUEST = "BAD_REQUEST",
    ACCOUNT_LOCKED = "ACCOUNT_LOCKED",
    ACCOUNT_NOT_ACTIVATED = "ACCOUNT_NOT_ACTIVATED",
    ACCOUNT_NOT_FOUND = "ACCOUNT_NOT_FOUND",
    BAD_CREDENTIALS = "BAD_CREDENTIALS",
    USERNAME_ALREADY_EXISTS = "USERNAME_ALREADY_EXISTS",
    EMAIL_ALREADY_EXISTS = "EMAIL_ALREADY_EXISTS",
}

export const getApiErrorModalTitle = (error: Error | unknown): string | undefined => {
    if (error instanceof ApiError) {
        return error.i18nKey;
    }

    return undefined;
};

export { ApiError, ApiErrorCode };
