import * as React from "react";
import { SVGProps } from "react";
const SvgIconHomeHeart = (props: SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" focusable={false} {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m3.75 10.753-.55.413a.75.75 0 0 1-.9-1.2l8.35-6.263c.8-.6 1.9-.6 2.7 0l8.35 6.263a.75.75 0 1 1-.9 1.2l-.55-.413V18.5A2.25 2.25 0 0 1 18 20.75H6a2.25 2.25 0 0 1-2.25-2.25v-7.747Zm7.8-5.85a.75.75 0 0 1 .9 0l6.3 4.725V18.5a.75.75 0 0 1-.75.75H6a.75.75 0 0 1-.75-.75V9.628l6.3-4.725Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.963 11.761a.916.916 0 0 0-1.295 1.295l2.326 2.326.002.001H12l.002-.001 2.329-2.326a.916.916 0 0 0-1.295-1.295l-.507.507a.75.75 0 0 1-1.06 0l-.507-.507ZM12 10.677a2.415 2.415 0 0 0-3.393 3.44l2.327 2.326c.587.587 1.54.588 2.128 0l2.33-2.326A2.416 2.416 0 0 0 12 10.677Z"
        />
    </svg>
);
export default SvgIconHomeHeart;
