import React from "react";
import { Paths } from "@models/Paths";
import SvgIconHomeHeart from "@components/svg/IconHomeHeart";
import SvgIconFamilyHeart from "@components/svg/IconFamilyHeart";
import SvgIconBoxes from "@components/svg/IconBoxes";

export interface MenuInterface {
    id: string;
    path: string;
    onClick?: () => void;
    icon?: React.ReactElement;
}

export const MenuItems: MenuInterface[] = [
    {
        id: "dashboard",
        path: Paths.dashboard,
        icon: <SvgIconHomeHeart />,
    },
    {
        id: "accounts",
        path: Paths.accounts,
        icon: <SvgIconFamilyHeart />,
    },
    {
        id: "products",
        path: Paths.products,
        icon: <SvgIconBoxes />,
    },
];
