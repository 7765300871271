import * as React from "react";
import { SVGProps } from "react";
const SvgIconBoxes = (props: SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 24 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg" focusable={false} {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.337 2.89a.75.75 0 0 0-.671 0L7.04 5.203a.75.75 0 0 0-.415.67v5.031l-4.21 2.105A.75.75 0 0 0 2 13.68v5.494c0 .284.16.544.415.67l4.626 2.314a.75.75 0 0 0 .67 0l4.291-2.145 4.29 2.145a.75.75 0 0 0 .672 0l4.626-2.313a.75.75 0 0 0 .415-.671V13.68a.75.75 0 0 0-.415-.67l-4.212-2.107v-5.03a.75.75 0 0 0-.415-.67L12.337 2.89Zm-4.962 9.316L4.428 13.68l2.948 1.474 2.948-1.474-2.949-1.474ZM3.5 14.893v3.817l3.125 1.563v-3.817L3.5 14.893Zm4.625 5.381v-3.817l3.127-1.563v3.817l-3.127 1.563Zm4.628-1.563 3.126 1.563v-3.817l-3.126-1.563v3.817Zm-1.501-6.245V8.652L8.125 7.089v3.814l3.127 1.563Zm9.253 6.245-3.126 1.562v-3.817l3.126-1.563v3.817Zm-3.877-6.505 2.949 1.474-2.949 1.474-2.948-1.474 2.948-1.474Zm-3.876.26 3.126-1.562V7.089L12.752 8.65v3.816Zm2.2-6.592L12.002 4.4 9.05 5.874 12 7.35l2.95-1.476Z"
        />
    </svg>
);
export default SvgIconBoxes;
